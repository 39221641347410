import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

const styleTittle = {

    fontSize: 20,

    color: "#4a54f1",

    textAlign: "center",

    paddingBottom: "60px",
}

const TambahNasabah = () => {
const [nama, setNama] = useState("");
const [alamat, setAlamat] = useState("");
const [rekTabungan, setRekTabungan] = useState("-----");
const [rekKredit, setRekKredit] = useState("");
const [rekVA, setRekVA] = useState("");
const [plafondKredit, setPlafondKredit] = useState("");
const [bakiDebet, setBakiDebet] = useState("");
const navigate = useNavigate();

const [kategori,setKategori] = useState([]);

const saveNasabah = async (e) =>{
    e.preventDefault();

    let formData = new FormData();
            formData.append('nama',nama);
            formData.append('alamat',alamat);
            formData.append('rekTabungan',rekTabungan);
            formData.append('rekKredit',rekKredit);
            formData.append('rekVA',rekVA);
            formData.append('plafondKredit',plafondKredit);
            formData.append('bakiDebet',bakiDebet);
    try {
        await axios.post(`${process.env.REACT_APP_MASTER_URL}/create`,formData,{
            headers:{ 
                'Content-Type':'multipart/form-data'
            }
        });
        navigate("/")
    } catch (error) {
        console.log("error: ",error);
    }
}

  return (
        <div className="section is-fullheight" style={{ 
            backgroundImage: `url("${process.env.REACT_APP_MASTER_URL}/assets/bg.png")`, 
            filterImage:'blur(8px)'}} >
        <div className="container is-centered">
            <div className="column is-6 is-offset-3">
            <div className="box" >
                    <img src={`${process.env.REACT_APP_MASTER_URL}/assets/logonusamba.png`}  alt="Not Found" width={300} height={300}></img>
                    <label className='label' style={styleTittle}><center>Form Input Nasabah</center></label>
                <form onSubmit={saveNasabah}>
                    <div className='field'>
                            <label className='label'>Nama Nasabah</label>
                            <div class='control' has-icons-left has-icons-right>
                                <input required type='text' className="input" value={nama} onChange={(e)=> setNama(e.target.value)} placeholder='Nama Nasabah'></input>                    </div>
                        </div>
                    <div className='field'>
                        <label className='label'>Alamat</label>
                        <div class='control' has-icons-left has-icons-right>
                            <textarea required type='text' style={{ height: '100px' }} className="input" value={alamat} onChange={(e)=> setAlamat(e.target.value)} placeholder='Alamat'></textarea>                    </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Rek. Tabungan</label>
                        <div className='control'>
                            <input required type='number' className="input" value={rekTabungan} onChange={(e)=> setRekTabungan(e.target.value)} placeholder='Masukan Angka'></input>
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Rek. Kredit</label>
                        <div className='control'>
                            <input required type='number' className="input" value={rekKredit} onChange={(e)=> setRekKredit(e.target.value)} placeholder='Masukan Angka'></input>
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Rek. VA </label>
                        <div className='control'>
                            <input required type='number' className="input" value={rekVA} onChange={(e)=> setRekVA(e.target.value)} placeholder='Stok Saat Ini'></input>
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Plafond Kredit</label>
                        <div className='control'>
                        <CurrencyInput
                            required
                            className='input'
                            prefix='Rp.'
                            value={plafondKredit}
                            onValueChange={(value, name) => setPlafondKredit(value)}
                            placeholder="Masukan Jumlah Nominal"
                            maxLength={10}
                            decimalsLimit={2}
                            />  
                            {/* <input required type='text' className="input" value={plafondKredit} onChange={(e)=> setPlafondKredit(e.target.value)} placeholder='Keterangan'></input> */}
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Baki Debet</label>
                        <div className='control'>
                        <CurrencyInput
                            required
                            className='input'
                            prefix='Rp.'
                            value={bakiDebet}
                            onValueChange={(value, name) => setBakiDebet(value)}
                            placeholder="Masukan Jumlah Nominal"
                            maxLength={10}
                            decimalsLimit={2}
                            />  
                        </div>
                    </div>
                    <div className='field mt-5'>
                        <button type='submit' className='button is-link'>Save</button>
                        <button type="button" onClick={() => navigate(-1)} className='button is-primary ml-5'>Back</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
        </div>
  )
}

export default TambahNasabah