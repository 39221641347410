//rafce
import React, {useState,useEffect,useRef,useMemo} from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "./Dialog";
import MaterialReactTable from 'material-react-table';
import { Button, Container, Row, Col, Form, Modal, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ExcelExport,ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process as processex} from "@progress/kendo-data-query";
import { FcPrint } from "react-icons/fc";
import CurrencyFormat from 'react-currency-format';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import RiseLoader from "react-spinners/RiseLoader";
import SideBar from "./sidebar/Sidebar";


//DONT DELETE!!
// Sourch Code reference from : https://www.telerik.com/kendo-react-ui/components/excelexport/
//                    https://www.npmjs.com/package/material-react-table

const styleTittle = {

  fontSize: 20,
  color: "#4a54f1",
  textAlign: "center",
  paddingBottom: "20px",
  paddingTop: "5px",

}

const stylespinner = {
  position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
}

const Dashboard = () => {

  const month = ["January","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
  const [loading, setLoading] = useState(true);


    const [nasabah,setNasabah] = useState([]);
    const [role, setRole] = useState("");
    const [nasabahData, setNasabahData] = useState(null);
    const [token, setToken] = useState("");
    const [expire, setExpire] = useState("");
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const _exporter = React.createRef();

    useEffect(() => {
      refreshToken();
  },[]);
  
  useEffect(() => {
        if (role) {
          getNasabah();
        }
      }, [role]);

    const handleShowModal = async (idNasabah) => {
      const data = await getNasabahByid(idNasabah);
      setNasabahData(data);
      setShowModal(true);
    };
    
    const handleCloseModal = () => {
      setShowModal(false);
    };
    
    const refreshToken = async() => {
      try {
            const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setExpire(decoded.exp);
    
      } catch (error) {
          if(error.response){
            navigate("/login");
          }
      }
    }

    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async(config) => {
      const curretDate = new Date();
      if(expire * 1000 < curretDate.getTime()){
          const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
          config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setRole(decoded.role);
          setExpire(decoded.exp);
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });


const getNasabah = async () => {
  // setLoading(true)
  const response = await axiosJWT.get(`${process.env.REACT_APP_MASTER_URL}/`,{
    headers:{
        Authorization:`Bearer ${token}`
    }
});
  setNasabah(response.data);
  setLoading(false);
}

const getNasabahByid = async (id) =>{
  try {
    const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching nasabah data:', error);
    return null;
  }

};

const [dialog, setDialog] = useState({
    message:"",
    isLoading:false,
    nama:""
});

const handleDialog = (message, isLoading, nama)=> {
    setDialog({
        message,
        isLoading,
        nama
    });
};

const idNasabahRef = useRef();

const handleDelete = (idNasabah,nama)=> {
    handleDialog("Yakin Ingin Hapus?",true,nama);
    idNasabahRef.current = idNasabah;
    
};

const areUSureDelete = (choose) => {
    if (choose) {
      deletenasabah(idNasabahRef.current);   
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

const deletenasabah = async (idNasabah) =>{
    try {
        await axios.delete(`${process.env.REACT_APP_MASTER_URL}/delete/${idNasabah}`);
        getNasabah();
    } catch (error) {
        console.log(error);
        
    }
}
  
  //Table
  const columns = useMemo(
    () => [
   
      {
        accessorKey: "nama", //simple recommended way to define a column
        header: "Nama",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>, //optional custom cell render
      },
      {
        accessorKey: "plafondKredit", //simple recommended way to define a column
        header: "Plafond Kredit",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong><CurrencyFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /></strong> //optional custom cell render
      },
      {
        accessorKey: "updatedAt",
        header: "Tanggal",
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => {
          const dateParts = cell.getValue().split("-");
          const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
          return <strong>{formattedDate}</strong>;
        },
      },
      {
        accessorKey: "superAdmin", //simple recommended way to define a column (toString(role) === toString(compare) ? show : null)
        header: "Aksi",
        muiTableHeadCellProps: { sx: { color: "red" } },
        //custom props
        Cell: (props) => (
                            <a>
                                <>
                                  <Link to={`edit/${props.row.original.idNasabah}`} className="button is-small is-link">Edit</Link>
                                  <button onClick={() => handleDelete(props.row.original.idNasabah, props.row.original.nama)} className="button is-small is-danger">Delete</button>
                                  <button className="button is-small is-success" onClick={() => handleShowModal(props.row.original.idNasabah)}>
                                    Detail
                                  </button>
                                </>
                            </a>
                          ),
        },
        {
          accessorKey: "Admin", //simple recommended way to define a column (toString(role) === toString(compare) ? show : null)
          header: "Aksi",
          muiTableHeadCellProps: { sx: { color: "red" } },
          //custom props
          Cell: (props) => (
                              <a>
                                    <button className="button is-small is-success" onClick={() => handleShowModal(props.row.original.idNasabah)}>
                                      Detail
                                    </button>
                              </a>
                            ),
          }
      ],
    []
  ); 

  //Data Excel
  const data = processex(nasabah,{}).data;
    const excelExport = () => {
      if (_exporter.current) {
        _exporter.current.save();
      }
    };

    const columnspdf = [
      { title: "Nama", field: "nama", },
      { title: "Alamat", field: "alamat", },
      { title: "Rek. Tabungan", field: "rekTabungan",type: "number" },
      { title: "Rek. Kredit", field: 'rekKredit', type: "number" },
      { title: "Rek. VA", field: 'rekVA',type: "number" },
      { title: "Plafond Kredit", field: 'plafondKredit',type: "number" },
      { title: "Baki Debet", field: 'bakiDebet',type: "number" }]

    const downloadPdf = () => {
      const doc = new jsPDF();
      const logoImg = new Image();
      logoImg.src = `${process.env.REACT_APP_MASTER_URL}/assets/logonusamba.png`;
    
      logoImg.onload = function () {
        // Menambahkan gambar ke PDF
        doc.addImage(logoImg, 'PNG', 19, 2, 30, 12);
    
        doc.setFontSize(14);
        doc.text("Daftar Nasabah Kredit", 102, 10, 'center');
        doc.setFontSize(10);
        doc.text(`Tanggal: ${new Date().getDate()} ${month[new Date().getMonth()]} ${new Date().getFullYear()}`, 18, 27);
        doc.autoTable({
          theme: "grid",
          columns: columnspdf.map(col => ({ ...col, dataKey: col.field })),
          margin: { top: 30 },
          body: nasabah
        });
    
        doc.save(`Daftar Nasabah Kredit ${new Date().getDate()}-${month[new Date().getMonth()]}-${new Date().getFullYear()}.pdf`);
      };
    };
    
   
  return (
  <div>
        {loading ?
          <div style={stylespinner}>
            <RiseLoader
              color={'#301E67'}
              loading={loading}
              size={20}
              textAlign='centered'
              aria-label="Loading Spinner"
              data-testid="loader"
            />
           </div> 
            :
    <SideBar>
        <div className="columnms mt-5 is centered">
        <img src={`${process.env.REACT_APP_MASTER_URL}/assets/logonusamba.png`}  alt="Not Found" width={300} height={300}></img>
              <label className='label' style={styleTittle}><center>INFORMASI KREDIT <p/>BPR NUSAMBA BRONDONG</center></label>
              {role==='superAdmin' ? <Link to={'tambah'} className="button is-link">Tambah Baru</Link> : null }
              {role==='superAdmin' ? <button onClick={downloadPdf} className="button is-link ml-5">Export PDF</button> : null }
              {role==='superAdmin' ? <button className="button is-danger ml-5" onClick={excelExport}>
                      Export to Excel
              </button> : null }
              
              <ExcelExport
                          data={data}
                          collapsible={true}
                          fileName={`Daftar Nasabah Kredit ${new Date().getDay()}-${month[new Date().getMonth()]}-${new Date().getFullYear()}.xlsx`}
                          ref={_exporter}>
                          <ExcelExportColumn field="nama" title="Nama Nasabah" width={200} />
                          <ExcelExportColumn field="alamat" title="Alamat" />
                          <ExcelExportColumn field="rekTabungan" title="rekTabungan"/>
                          <ExcelExportColumn field="rekKredit" title="rekKredit" />
                          <ExcelExportColumn field="rekVA" title="rekVA" />
                          <ExcelExportColumn field="plafondKredit" title="plafondKredit" />
                          <ExcelExportColumn field="bakiDebet" title="bakiDebet" />
              </ExcelExport>

              <Modal show={showModal} onHide={handleCloseModal} size="small" centered >
                  <Modal.Header closeButton>
                    <Modal.Title>Detail</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      {nasabahData ? (
                        <Table>
                          <tbody>
                            <tr>
                              <td style={{width:'170px'}}>Nama</td>
                              <td style={{textAlign:'left',width:'30px'}}>:</td>
                              <td>{nasabahData.nama}</td>
                            </tr>
                            <tr>
                              <td style={{width:'170px'}}>Alamat</td>
                              <td style={{textAlign:'left',width:'30px'}}>:</td>
                              <td>{nasabahData.alamat}</td>
                            </tr>
                            <tr>
                              <td style={{width:'170px'}}>Rekening Tabungan</td>
                              <td style={{textAlign:'left',width:'30px'}}>:</td>
                              <td>{nasabahData.rekTabungan}</td>
                            </tr>
                            <tr>
                              <td style={{width:'170px'}}>Rekening Kredit</td>
                              <td style={{textAlign:'left',width:'30px'}}>:</td>
                              <td>{nasabahData.rekKredit}</td>
                            </tr>
                            <tr>
                              <td style={{width:'170px'}}>Rekening VA</td>
                              <td style={{textAlign:'left',width:'30px'}}>:</td>
                              <td>{nasabahData.rekVA}</td>
                            </tr>
                            <tr>
                              <td style={{width:'170px'}}>Plafond Kredit</td>
                              <td style={{textAlign:'left',width:'30px'}}>:</td>
                              <td><CurrencyFormat value={nasabahData.plafondKredit} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /></td>
                            </tr>
                            <tr>
                              <td style={{width:'170px'}}>Baki Debet</td>
                              <td style={{textAlign:'left',width:'30px'}}>:</td>
                              <td><CurrencyFormat value={nasabahData.bakiDebet} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /></td>
                            </tr>
                            <tr>
                              <td style={{ width: '170px' }}>Tanggal Update</td>
                              <td style={{ textAlign: 'left', width: '30px' }}>:</td>
                              <td>
                                {nasabahData.updatedAt && (
                                  <span>
                                    {new Date(nasabahData.updatedAt).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    }).split('/').join('-')}
                                  </span>
                                )}
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      ) : (
                        "Memuat..."
                      )}
                    </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Tutup
                    </Button>
                  </Modal.Footer>
          </Modal>

                {role==='superAdmin' ?
              <MaterialReactTable 
                            enableRowNumbers
                            rowNumberMode="original"
                            initialState={{ columnVisibility: { 'Admin': false } } }
                            columns={columns} data={nasabah} 
                            // enableStickyHeader
                            enableStickyFooter
                            defaultColumn={{
                            minSize: 10, //allow columns to get smaller than default
                            maxSize: 200, //allow columns to get larger than default
                            size: 260, //make columns wider by default
                        }}
                    /> :
                    <MaterialReactTable 
                            enableRowNumbers
                            rowNumberMode="original"
                            initialState={{ columnVisibility: { 'superAdmin': false } } }
                            columns={columns} data={nasabah} 
                            // enableStickyHeader
                            enableStickyFooter
                            defaultColumn={{
                            minSize: 10, //allow columns to get smaller than default
                            maxSize: 200, //allow columns to get larger than default
                            size: 260, //make columns wider by default
                        }}
                    /> 
                    }

              {dialog.isLoading && (
                  <Dialog
                  //Update
                  nameProduct={dialog.nama}
                  onDialog={areUSureDelete}
                  message={dialog.message}
                  />
                  )}
      </div>   
      
    </SideBar> 
    
      }
  </div>
  )
}

export default Dashboard