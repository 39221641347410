import React, { useState, useEffect } from "react";
import RiseLoader from "react-spinners/RiseLoader";

const stylespinner = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
};

const ModalView = () => {
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Proses memuat data atau tindakan lainnya

  //   // Setelah proses memuat selesai, perbarui nilai loading menjadi false
  //   setLoading(false);
  // }, []);

  return (
    <div>
      {loading && (
        <div style={stylespinner}>
          <RiseLoader
            color="#301E67"
            loading={loading}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
};

export default ModalView;
