import "./App.css";
import {BrowserRouter, Routes, Route}  from "react-router-dom";
import Login from "./component/Login";
import LogOut from "./component/LogOut";
import Register from "./component/Register";
import User from "./component/UserList";
import EditUser from "./component/EditUser";
import Dashboard from "./component/Dashboard";
import TambahNasabah from "./component/TambahNasabah";
import EditNasabah from "./component/EditNasabah";
import ModalView from "./component/ModalView";
import React from 'react'
// import Navbar from "./component/Navbar";
// import SideBar from "./component/sidebar/Sidebar";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route path="/logout" element={<LogOut/>}/>
        <Route path="/tambah" element={<TambahNasabah/>}/>
        <Route path="edit/:id" element={<EditNasabah/>}/>
        <Route path="/users/edit/:id" element={<EditUser/>}/>
        <Route path="/users/register" element={<Register/>}/>
        <Route path="/" element={<Dashboard/>}/>
        <Route path="/modal" element={<ModalView/>}/>
        <Route path="/users" element={<><User/></>}/>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
