import React, {useState,useEffect} from "react";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {useNavigate, useParams} from "react-router-dom";

const Register = () => {
    const {id} = useParams();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [passwordNew, setPasswordNew] = useState("");
    const [token, setToken] = useState("");
    const [role, setRole] = useState("");
    const [expire, setExpire] = useState("");
    const [confPasswordNew, setConfPasswordNew] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const styleTittle = {

        fontSize: 20,
    
        color: "#4a54f1",
    
        textAlign: "center",
    
        paddingBottom: "10px",
    }

    useEffect(() => {
        refreshToken();
        getUserByid();
        
    },[]);

    const refreshToken = async() => {
        try {
              const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setRole(decoded.role);
              setExpire(decoded.exp);
              
      
        } catch (error) {
            if(error.response){
              navigate("/login");
            }
        }
      }
      
      const axiosJWT = axios.create();
      axiosJWT.interceptors.request.use(async(config) => {
          const curretDate = new Date();
          if(expire * 1000 < curretDate.getTime()){
              const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
              config.headers.Authorization = `Bearer ${response.data.accessToken}`;
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setRole(decoded.role);
              setExpire(decoded.exp);
          }
          return config;
      }, (error) => {
          return Promise.reject(error);
      });
      
      
      // ----END---

    const editUser = async (e) =>{
        e.preventDefault();

        let formData = new FormData();
            formData.append('username',username);
            formData.append('email',email);
            formData.append('password',passwordNew);
            formData.append('confPassword',confPasswordNew);
        try {
            await axios.patch(`${process.env.REACT_APP_MASTER_URL}/users/update/${id}`,formData,{
                headers:{ 
                    'Content-Type':'multipart/form-data'
                }
            });
            navigate("/users")
            alert("Password Berhasil Diubah...")
        } catch (error) {
            if(error.response){
               setMsg(error.response.data.msg);
            }
            
        }
            
    }

    const getUserByid = async () =>{
        const response = await axiosJWT.get(`${process.env.REACT_APP_MASTER_URL}/users/${id}`,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        setUsername(response.data[0].username);
    
    };


  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-4-desktop">
                    
                    <form className="box" onSubmit={editUser}>
                    <img src={`${process.env.REACT_APP_MASTER_URL}/assets/logonusamba.png`}  alt="Not Found" width={300} height={300}></img>
                    <label className='label' style={styleTittle}><center>Form Edit User</center></label>
                    <p style={{color:"red"}} className="has-text-centered">{msg}</p>
                    <div className="filed mt-5">
                            <label className="label">Username</label>
                            <div className="controls">
                                <input disabled type="text" className="input" value={username} onChange={(e)=> setUsername(e.target.value)} placeholder="Username"/>
                            </div>
                        </div>
                        <div className="filed mt-5">
                            <label className="label">Password Baru</label>
                            <div className="controls">
                                <input required type="password" className="input" value={passwordNew} onChange={(e)=> setPasswordNew(e.target.value)} placeholder="*****"/>
                            </div>
                        </div>
                        <div className="filed mt-5">
                            <label className="label">Konfirmasi Password Baru</label>
                            <div className="controls">
                                <input required type="password" className="input" value={confPasswordNew} onChange={(e)=> setConfPasswordNew(e.target.value)} placeholder="*****"/>
                            </div>
                        </div>
                        <div className='field mt-5'>
                            <button type="button" onClick={() => navigate(-1)} className='button is-primary mr-5'>Kembali</button>
                            <button type='submit' className='button is-link'>Update</button>
                            
                        </div>
                    </form>
                </div>

            </div>
          
        </div>
      </div>
    </section>
  )
}

export default Register