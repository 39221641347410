import {React, useEffect, useState} from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate,Link } from "react-router-dom";

const styleTittle = {

    fontSize: 20,
    color: "red",
    textAlign: "center",
    paddingBottom: "20px",
    paddingTop: "5px",
  
  }

const LogOut = () => {
      const navigate = useNavigate();

      useEffect(() => {
        refreshToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

      const [userId, setUserId] = useState("");
      const [token, setToken] = useState("");
      const [expire, setExpire] = useState("");
  
      const axiosJWT = axios.create();
      axiosJWT.interceptors.request.use(async(config) => {
        const curretDate = new Date();
        if(expire * 1000 < curretDate.getTime()){
            const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            // console.log(decoded);
            // setUsername(decoded.username);
            // setEmail(decoded.email);
            setUserId(decoded.userId);
            // console.log("ini jwt: ",role);
            setExpire(decoded.exp);
        }
        return config;
      }, (error) => {
        return Promise.reject(error);
      });
  
    const refreshToken = async() => {
    try {
          const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          // console.log("ini decode: ",decoded);
          // setUsername(decoded.username);
          // setEmail(decoded.email);
          setUserId(decoded.userId);
          setExpire(decoded.exp);
  
    } catch (error) {
        if(error.response){
          navigate("/login");
        }
    }
  }      


      const Logout = async() => {
        try {
              await axios.delete(`${process.env.REACT_APP_MASTER_URL}/logout`)
              navigate('/login');
        } catch (error) {
            console.log(error);
        }
      }
      
  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-4-desktop">
                <div class="box has-text-centered">
                     <img src={`${process.env.REACT_APP_MASTER_URL}/assets/logonusamba.png`}  alt="Not Found" width={300} height={300}/>
                     <label className='label' style={styleTittle}><center>Ingin Logout ?</center></label>        
                     <button onClick={Logout} className="button is-danger mr-3">
                        YA
                    </button>
                    <Link to={'/'} className="button is-link">Tidak</Link>
                </div>
                </div>

            </div>
          
        </div>
      </div>
    </section>
  )
}

export default LogOut